import { defineStore } from "pinia";
import { ref } from "vue";
import { useURLs } from "~~/constants/api.urls";
import { CLD_ENV } from "~~/constants/env";

export const useShippingStore = defineStore("shipping", () => {
  const nuxtApp = useNuxtApp();

  const shippingCost = ref();
  const isShippingCostAdded = ref();

  const CheckPresenceOfShippingCost = async () => {
    const url = useURLs[CLD_ENV].CHECK_PRESENCE_SHIPPING_COST;
    const token = nuxtApp.$nuxtStorage.localStorage.getData("token");

    if (!token) {
      return false;
    }
    const { data } = await useFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.value) {
      isShippingCostAdded.value = data.value.data.Data;
    }
  };

  const AddShippingCost = async () => {
    const url = useURLs[CLD_ENV].ADD_SHIPPING_COST;
    const token = nuxtApp.$nuxtStorage.localStorage.getData("token");
    if (!token) {
      return false;
    }
    const { data } = await useFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    shippingCost.value = data.value.data.Data;
    isShippingCostAdded.value = data.value.data.Data;
  };
  return {
    shippingCost,
    isShippingCostAdded,
    AddShippingCost,
    CheckPresenceOfShippingCost,
  };
});
